import React from "react";
import './App.css';
import { ThemeProvider } from '@emotion/react';
import {theme} from "./theming/theme";
import SghiriRouter from './routing/router';
import {CssBaseline} from "@mui/material"
function App() {
  return (
      <ThemeProvider theme={theme}>
          <CssBaseline/>
          <SghiriRouter/>
      </ThemeProvider>
  );
}

export default App;
