import React, {useState} from "react";
import { Grid, TextField} from "@mui/material"
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import IconButton from "@mui/material/IconButton";

export default function SearchBar() {
    const [search, setSearch] = useState("");

    return (
        <>
                <Grid container item xs={10}>
                    <TextField
                        fullWidth
                        id="search"
                        value={search}
                        placeholder="Rechercher un produit"
                        InputProps={{
                            inputProps: {
                                style: {
                                    color: '#fff'
                                }
                            },
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon/>
                                </InputAdornment>
                            )
                        }}
                        onChange={(e) => {
                            setSearch(e.target.value)
                        }}
                    />
                </Grid>
                <Grid container item xs={2}>
                    <IconButton size="large" color="primary" aria-label="upload picture" component="span">
                        <SearchIcon/>
                    </IconButton>
                </Grid>
        </>
    )
}