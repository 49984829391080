import React  from "react";
import {
    BrowserRouter as Router,
    Routes,
    Route,
  } from "react-router-dom";
import Layout from "../layouts/layout";
import routesName from "./routing";
import UnderConstruction from "../pages/common/under_construction";



export default function SghiriRouter(){

    const underConstruction = true;

    return (
        <Layout>
          <Router>
              <Routes>
                  {
                      underConstruction ?
                          <>
                              <Route path={routesName.terms.path}  element={routesName.terms.element}/>
                              <Route path="*"  element={<UnderConstruction/>}/>
                          </>
                          :
                          <>
                              <Route path="/" exact element={routesName.home.element}/>
                              <Route path={routesName.terms.path}  element={routesName.terms.element}/>
                              <Route path={routesName.notFound.path}  element={routesName.notFound.element}/>
                          </>
                  }
              </Routes>
          </Router>
        </Layout>
    )
}