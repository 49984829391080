import React from "react";
import {Grid} from "@mui/material";
import Image from "mui-image";
import Typography from "@material-ui/core/Typography";
import Stack from "@mui/material/Stack";


export default function UnderConstruction() {
        return(
            <Grid container item xs={12} sx={{padding : 8}} spacing={4}>
                <Grid container item xs={12} sm={4} alignContent="center">
                    <Stack justifyContent="center">
                        <Typography textAlign="center" variant={"h2"}>
                            Oops!
                        </Typography>
                        <Typography variant={"h4"}>
                            Site en construction
                        </Typography>
                        <Typography variant={"h6"}>
                            Nous travaillons actuellement sur le site. Merci pour votre patience.
                        </Typography>
                    </Stack>
                </Grid>
                <Grid container item xs={12} sm={8} alignContent={"center"} justifyContent="center">
                    <Image fit="contain" width="80%" src="/under_construction-01.png"/>
                </Grid>
            </Grid>
        )
}