import React, {useState} from "react";
import {Grid, Paper, Container, Hidden} from "@mui/material"
import Logo from "../pages/components/NavBar/logo";
import SearchBar from "../pages/components/NavBar/searchBar";
import Menu from "../pages/components/NavBar/menu";
import Slide from "@material-ui/core/Slide";
import MenuItems from "../pages/components/NavBar/menuElement";


export default function Layout({children}){
    const [showMenu , setShowMenu ] = useState(true);

    function toggleMenu(){
        setShowMenu(!showMenu);
    }
    return(
        <Grid container item xs={12}>
            <Grid 
                container 
                item 
                xs={12}
                component={Paper}
                elevation={18}
                sx={{
                    height : 100,
                    backgroundColor : (theme) => theme.palette.secondary.main,
                    padding : 2,
                    borderRadius :0,
                    color : "white",
                    zIndex : 9999,
                }}
                alignContent="center"
            >
                <Container>
                    <Grid container item xs={12} alignContent="center" spacing={2}>
                        <Grid container item xs={3}>
                            <Logo/>
                        </Grid>
                        <Grid
                            container
                            item
                            xs={9}
                            alignContent="center"
                            sx={{ flexDirection: { xs: "row-reverse", md: "row-reverse",lg: "row"} }}
                        >
                            <Grid
                                container
                                item
                                xs={2} md={4}
                                alignContent="center"
                                sx={{ justifyContent : {xs : "flex-end", md : "flex-end",lg : "center"}}}
                            >
                                <Menu toggleMenu={toggleMenu}/>
                            </Grid>
                            <Grid container item xs={10} md={8} spacing={2}>
                                <SearchBar/>
                            </Grid>

                        </Grid>
                    </Grid>
                </Container>
            </Grid>
            <Hidden lgUp>
                <Slide direction="down" in={showMenu} mountOnEnter unmountOnExit>
                    <Grid
                        container
                        item
                        xs={12}
                        alignContent="center"
                        justifyContent="center"
                        sx={{
                            height : 50,
                            backgroundColor : (t) => t.palette.primary.main
                        }}
                    >
                        <MenuItems/>
                    </Grid>
                </Slide>
            </Hidden>
            <Grid 
                container 
                item 
                xs={12}
                sx={{
                    marginTop : 10
                }}
            >
                {children}
            </Grid>
        </Grid>
    )
}