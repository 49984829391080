import React from "react";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";




export default function MenuItems() {
    return(
        <Stack sx={{ color : "white"}} direction="row" spacing={2}>
            <Button color="inherit">
                Produits
            </Button>
            <Button color="inherit">
                Contact
            </Button>
        </Stack>
    )
}