import React from "react";
import {Grid,Hidden} from "@mui/material";
import {Image} from "mui-image";





export default function Logo(){


    return(
        <Grid container  item xs={12} alignContent="center">
            <Hidden mdUp>
                <Image fit={"contain"} style={{height : 150}} alt="logo" src="logo-01.png"/>
            </Hidden>
            <Hidden mdDown>
                <Image fit={"contain"} height="80%"   width={"100%"} alt="logo" src="logo-01.png"/>
            </Hidden>
        </Grid>
    )
}