import React from "react";
import MenuIcon from "@mui/icons-material/Menu";
import IconButton from "@mui/material/IconButton";
import Hidden from "@mui/material/Hidden";
import MenuItems from "./menuElement";



export default function Menu({toggleMenu}) {

    return(
        <>
            <Hidden lgDown>
                <MenuItems/>
            </Hidden>
            <Hidden lgUp>
                <IconButton  onClick={() => toggleMenu()} size="large" color="primary" aria-label="upload picture" component="span">
                    <MenuIcon />
                </IconButton>
            </Hidden>
        </>
    )
}