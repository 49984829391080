import React from "react";
import { Grid, Typography } from "@mui/material";
import Link from "@material-ui/core/Link";
import Stack from "@mui/material/Stack";
import Container from "@material-ui/core/Container";




export default function Terms(){


    return(
        <Container maxWidth="lg">
            <Grid container item xs={12} alignContent={"center"} sx={{padding : 4}}>
                <Stack spacing={2} style={{width : "80%"}}>
                    <Typography textAlign="center" variant={"h5"} fontWeight={900}>POLITIQUE DE CONFIDENTIALITÉ DU SITE : <Link style={{cursor : "pointer"}}>www.sghiri.tn</Link></Typography>
                    <Typography variant={"h6"} fontWeight={900}>ARTICLE 1 : PRÉAMBULE</Typography>
                    <Typography variant={"body1"} fontWeight={900}>
                        Cette politique de confidentialité s'applique au site : www.sghiri.tn.
                        La présente politique de confidentialité a pour but d'exposer aux utilisateurs du site :
                        La manière dont sont collectées et traitées leurs données à caractère personnel. Doivent être considérées comme données personnelles toutes les données étant susceptibles d'identifier un utilisateur. Il s'agit notamment du prénom et du nom, de l'âge, de l'adresse postale, l'adresse mail, la localisation de l'utilisateur ou encore son adresse IP ;
                        Quels sont les droits des utilisateurs concernant ces données ;
                        Qui est responsable du traitement des données à caractère personnel collectées et traitées ;
                        A qui ces données sont transmises ;
                        Eventuellement, la politique du site en matière de fichiers "cookies".
                        Cette politique de confidentialité complète les mentions légales et les Conditions Générales d'Utilisation que les utilisateurs peuvent consulter à l'adresse ci-après :
                        <Link style={{cursor : "pointer"}}>>www.sghiri.tn/terms</Link> .
                    </Typography>

                    <Typography variant={"h6"} fontWeight={900}>ARTICLE 2 : PRINCIPES GÉNÉRAUX EN MATIÈRE DE COLLECTE ET DE TRAITEMENT DE DONNÉES</Typography>
                    <Typography variant={"body1"} fontWeight={900}>
                        la collecte et le traitement des données des utilisateurs du site respectent les principes suivants : <br/>

                        Licéité, loyauté et transparence : les données ne peuvent être collectées et traitées qu'avec le consentement de l'utilisateur propriétaire des données. A chaque fois que des données à caractère personnel seront collectées, il sera indiqué à l'utilisateur que ses données sont collectées, et pour quelles raisons ses données sont collectées ;<br/>

                        Finalités limitées : la collecte et le traitement des données sont exécutés pour répondre à un ou plusieurs objectifs déterminés dans les présentes conditions générales d'utilisation ;<br/>

                        Minimisation de la collecte et du traitement des données : seules les données nécessaires à la bonne exécution des objectifs poursuivis par le site sont collectées ;<br/>

                        Conservation des données réduites dans le temps : les données sont conservées pour une durée limitée, dont l'utilisateur est informé. Lorsque cette information ne peut pas être communiquée, l'utilisateur est informé des critères utilisés pour déterminer la durée de conservation ;<br/>

                        Intégrité et confidentialité des données collectées et traitées : le responsable du traitement des données s'engage à garantir l'intégrité et la confidentialité des données collectées.<br/>

                        Afin d'être licites, et ce conformément aux exigences de l'article 6 du règlement européen 2016/679, la collecte et le traitement des données à caractère personnel ne pourront intervenir que s'ils respectent au moins l'une des conditions ci-après énumérées :<br/>
                        <br/>
                        - L'utilisateur a expressément consenti au traitement ;<br/><br/>
                        - Le traitement est nécessaire à la bonne exécution d'un contrat ;<br/><br/>
                        - Le traitement répond à une obligation légale ;<br/><br/>
                        - Le traitement s'explique par une nécessité liée à la sauvegarde des intérêts vitaux de la personne concernée ou d'une autre personne physique ;<br/><br/>
                        - Le traitement peut s'expliquer par une nécessité liée à l'exécution d'une mission d'intérêt public ou qui relève de l'exercice de l'autorité publique ;<br/><br/>
                        - Le traitement et la collecte des données à caractère personnel sont nécessaires aux fins des intérêts légitimes et privés poursuivis par le responsable du traitement ou par un tiers.<br/><br/>
                    </Typography>


                    <Typography variant={"h6"} color={"primary"} fontWeight={900}>ARTICLE 3 : DONNÉES À CARACTÈRE PERSONNEL COLLECTÉES ET TRAITÉES DANS LE CADRE DE LA NAVIGATION SUR LE SITE</Typography>
                    <Typography variant={"body1"} color={"secondary"} fontWeight={900}>A. DONNÉES COLLECTÉES ET TRAITÉES ET MODE DE COLLECTE</Typography>
                    <Typography variant={"body1"} fontWeight={900}>
                        Les données à caractère personnel collectées sur le site Sghiri.tn sont les suivantes :<br/>

                        Nom et prénom<br/>
                        Numéro de teléphone<br/>
                        Adresse, Ville et Région<br/>

                        Ces données sont collectées lorsque l'utilisateur effectue l'une des opérations suivantes sur le site :<br/>

                        Passer une commade sur le site<br/>

                        Par ailleurs, lors d'un paiement sur le site, il sera conservé dans les systèmes informatiques de l'éditeur du site une preuve de la transaction comprenant le bon de commande et la facture.<br/>

                        Le responsable du traitement conservera dans ses systèmes informatiques du site et dans des conditions raisonnables de sécurité l'ensemble des données collectées pour une durée de : une année.<br/>

                        La collecte et le traitement des données répondent aux finalités suivantes :<br/>

                        - traitement des commandes<br/><br/>
                        - expédition des colis<br/><br/>

                        Les traitements de données effectués sont fondés sur les bases légales suivantes :<br/>

                        Facture et bon de commande<br/>
                    </Typography>
                    <Typography variant={"body1"} color={"secondary"} fontWeight={900}>B. TRANSMISSION DES DONNÉES A DES TIERS</Typography>
                    <Typography variant={"body1"} fontWeight={900}>
                        Les données à caractère personnel collectées par le site ne sont transmises à aucun tiers, et ne sont traitées que par l'éditeur du site.
                    </Typography>

                    <Typography variant={"body1"} color={"secondary"} fontWeight={900}>C. HÉBERGEMENT DES DONNÉES</Typography>
                    <Typography variant={"body1"} fontWeight={900}>
                        Le site Sghiri.tn est hébergé par : OVH, dont le siège est situé à l'adresse ci-après :<br/>

                        R6QM+3WH, Passage du la Malawi, Tunis<br/>

                        L'hébergeur peut être contacté au numéro de téléphone suivant : 71 966 044.<br/>

                        Les données collectées et traitées par le site sont transférées vers le(s) pays suivant(s) : Tunisie.<br/>
                    </Typography>

                    <Typography variant={"h6"} color={"primary"} fontWeight={900}>ARTICLE 4 : RESPONSABLE DU TRAITEMENT DES DONNÉES</Typography>
                    <Typography variant={"body1"} color={"secondary"} fontWeight={900}>A. LE RESPONSABLE DU TRAITEMENT DES DONNÉES</Typography>
                    <Typography variant={"body1"} fontWeight={900}>
                        Le responsable du traitement des données à caractère personnel est : Chebil Alaeddine. Il peut être contacté de la manière suivante :<br/>

                        par telephone : +216 55 318 152<br/>
                        email : protorza@gmail.com<br/>

                        Le responsable du traitement des données est chargé de déterminer les finalités et les moyens mis au service du traitement des données à caractère personnel.<br/>

                    </Typography>
                    <Typography variant={"body1"} color={"success"} fontWeight={900}>B. OBLIGATIONS DU RESPONSABLE DU TRAITEMENT DES DONNÉES</Typography>
                    <Typography variant={"body1"} fontWeight={900}>
                        Le responsable du traitement s'engage à protéger les données à caractère personnel collectées, à ne pas les transmettre à des tiers sans que l'utilisateur n'en ait été informé et à respecter les finalités pour lesquelles ces données ont été collectées.<br/>

                        Le site dispose d'un certificat SSL afin de garantir que les informations et le transfert des données transitant par le site sont sécurisés.<br/>

                        Un certificat SSL ("Secure Socket Layer" Certificate) a pour but de sécuriser les données échangées entre l'utilisateur et le site.<br/>

                        De plus, le responsable du traitement des données s'engage à notifier l'utilisateur en cas de rectification ou de suppression des données, à moins que cela n'entraîne pour lui des formalités, coûts et démarches disproportionnés.<br/>

                        Dans le cas où l'intégrité, la confidentialité ou la sécurité des données à caractère personnel de l'utilisateur est compromise, le responsable du traitement s'engage à informer l'utilisateur par tout moyen.<br/>
                    </Typography>





                    <Typography variant={"h6"} color={"primary"} fontWeight={900}>ARTICLE 5 : DROITS DE L'UTILISATEUR</Typography>
                    <Typography variant={"body1"} fontWeight={900}>
                        Conformément à la réglementation concernant le traitement des données à caractère personnel, l'utilisateur possède les droits ci-après énumérés.<br/>

                        Afin que le responsable du traitement des données fasse droit à sa demande, l'utilisateur est tenu de lui communiquer : ses prénom et nom ainsi que son adresse e-mail.<br/>

                        Le responsable du traitement des données est tenu de répondre à l'utilisateur dans un délai de 30 (trente) jours maximum.<br/>

                    </Typography>

                    <Typography variant={"body1"} color={"secondary"} fontWeight={900}>A. PRÉSENTATION DES DROITS DE L'UTILISATEUR EN MATIÈRE DE COLLECTE ET TRAITEMENT DE DONNÉES</Typography>

                    <Typography variant={"body1"} fontWeight={900}>
                        a. Droit d'accès, de rectification et droit à l'effacement<br/>

                        L'utilisateur peut prendre connaissance, mettre à jour, modifier ou demander la suppression des données le concernant, en respectant la procédure ci-après énoncée :<br/>

                        L'utilisateur doit envoyer un e-mail au responsable du traitement des données personnelles, en précisant l'objet de sa demande, à l'adresse e-mail de contact.<br/>

                        b. Droit à la portabilité des données<br/>

                        L'utilisateur a le droit de demander la portabilité de ses données personnelles, détenues par le site, vers un autre site, en se conformant à la procédure ci-après :<br/>

                        L'utilisateur doit envoyer un e-mail au responsable du traitement des données personnelles, en précisant l'objet de sa demande, à l'adresse e-mail de contact.<br/>


                        c. Droit à la limitation et à l'opposition du traitement des données<br/>

                        L'utilisateur a le droit de demander la limitation ou de s'opposer au traitement de ses données par le site, sans que le site ne puisse refuser, sauf à démontrer l'existence de motifs légitimes et impérieux, pouvant prévaloir sur les intérêts et les droits et libertés de l'utilisateur.<br/>

                        Afin de demander la limitation du traitement de ses données ou de formuler une opposition au traitement de ses données, l'utilisateur doit suivre la procédure suivante :<br/>

                        L'utilisateur doit envoyer un e-mail au responsable du traitement des données personnelles, en précisant l'objet de sa demande, à l'adresse e-mail de contact.<br/>

                        d. Droit de ne pas faire l'objet d'une décision fondée exclusivement sur un procédé automatisé<br/>

                        Conformément aux dispositions du règlement 2016/679, l'utilisateur a le droit de ne pas faire l'objet d'une décision fondée exclusivement sur un procédé automatisé si la décision produit des effets juridiques le concernant, ou l'affecte de manière significative de façon similaire.<br/>


                        e. Droit de déterminer le sort des données après la mort<br/>

                        Il est rappelé à l'utilisateur qu'il peut organiser quel doit être le devenir de ses données collectées et traitées s'il décède, conformément à la loi n°2016-1321 du 7 octobre 2016.<br/>


                        f. Droit de saisir l'autorité de contrôle compétente<br/>

                        Dans le cas où le responsable du traitement des données décide de ne pas répondre à la demande de l'utilisateur, et que l'utilisateur souhaite contester cette décision, ou, s'il pense qu'il est porté atteinte à l'un des droits énumérés ci-dessus, il est en droit de saisir la CNIL (Commission Nationale de l'Informatique et des Libertés, https://www.cnil.fr) ou tout juge compétent<br/>

                    </Typography>

                    <Typography variant={"h6"} color={"primary"} fontWeight={900}>ARTICLE 6 : UTILISATION DES FICHIERS "COOKIES"</Typography>
                    <Typography variant={"body1"} fontWeight={900}>
                        Le site a éventuellement recours aux techniques de "cookies".<br/>

                        Un "cookie" est un fichier de petite taille (moins de 4 ko), stocké par le site sur le disque dur de l'utilisateur, contenant des informations relatives aux habitudes de navigation de l'utilisateur.<br/>

                        Ces fichiers lui permettent de traiter des statistiques et des informations sur le trafic, de faciliter la navigation et d'améliorer le service pour le confort de l'utilisateur.<br/>

                        Pour l'utilisation de fichiers "cookies" impliquant la sauvegarde et l'analyse de données à caractère personnel, le consentement de l'utilisateur est nécessairement demandé.<br/>

                        Ce consentement de l'utilisateur est considéré comme valide pour une durée de 6 (six) mois maximum. A l'issue de cette période, le site demandera à nouveau l'autorisation de l'utilisateur pour enregistrer des fichiers "cookies" sur son disque dur.<br/>


                        a. Opposition de l'utilisateur à l'utilisation de fichiers "cookies" par le site<br/>

                        Les cookies non essentiels au fonctionnement du site ne sont déposés sur le terminal de l'utilisateur qu'après avoir obtenu son consentement. L'utilisateur peut retirer son consentement à tout moment, de la manière suivante :<br/>

                        Veuillez cliquer sur le bouton "retirer le consentement"<br/>

                        De manière plus générale, il est porté à la connaissance de l'utilisateur qu'il peut s'opposer à l'enregistrement de ces fichiers "cookies" en configurant son logiciel de navigation.<br/>

                        Pour information, l'utilisateur peut trouver aux adresses suivantes les démarches à suivre afin de configurer son logiciel de navigation pour s'opposer à l'enregistrement des fichiers "cookies" :<br/>

                        Chrome : <Link style={{cursor : "pointer"}}>https://support.google.com/accounts/answer/61416?hl=fr</Link><br/>

                        Firefox : <Link style={{cursor : "pointer"}}>https://support.mozilla.org/fr/kb/enable-and-disable-cookies-website-preferences</Link><br/>

                        Safari : <Link style={{cursor : "pointer"}}>http://www.apple.com/legal/privacy/fr-ww/</Link><br/>

                        Internet Explorer : <Link style={{cursor : "pointer"}}>https://support.microsoft.com/fr-fr/help/17442/windows-internet-explorer-delete-manage-cookies</Link><br/>

                        Opera : <Link style={{cursor : "pointer"}}>http://www.opera.com/help/tutorials/security/cookies/</Link><br/>


                        Dans le cas où l'utilisateur décide de désactiver les fichiers "cookies", il pourra poursuivre sa navigation sur le site. Toutefois, tout dysfonctionnement du site provoqué par cette manipulation ne pourrait être considéré comme étant du fait de l'éditeur du site.<br/>


                        b. Description des fichiers "cookies" utilisés par le site<br/>

                        L'éditeur du site attire l'attention de l'utilisateur sur le fait que les cookies suivants sont utilisés lors de sa navigation :<br/>

                        Cookie<br/>

                    </Typography>

                    <Typography variant={"h6"} color={"primary"} fontWeight={900}>ARTICLE 7 : CONDITIONS DE MODIFICATION DE LA POLITIQUE DE CONFIDENTIALITÉ</Typography>
                    <Typography variant={"body1"} fontWeight={900}>
                        La présente politique de confidentialité peut être consultée à tout moment à l'adresse ci-après indiquée :<br/>

                        https://www.sghiri.tn/politique_de_confidentialite<br/>

                        L'éditeur du site se réserve le droit de la modifier afin de garantir sa conformité avec le droit en vigueur.<br/>

                        Par conséquent, l'utilisateur est invité à venir consulter régulièrement cette politique de confidentialité afin de se tenir informé des derniers changements qui lui seront apportés.<br/>

                        Il est porté à la connaissance de l'utilisateur que la dernière mise à jour de la présente politique de confidentialité est intervenue le : 21/06/2022.<br/>

                    </Typography>





                </Stack>
            </Grid>
        </Container>
    )
}