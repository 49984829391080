import React from "react";




export default function Home() {

    return(

        <p>Home</p>
    )
}