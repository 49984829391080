import React from "react";
import Home from "../pages/home"
import Terms from "../pages/terms";


const routesName = {
    home : {
        path : "/",
        element : <Home/>
    },
    terms : {
        path : "/terms",
        element : <Terms/>
    },

    notFound : {
        path : "*",
        element : <p>NotFound</p>
    }
};



export default routesName;